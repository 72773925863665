<p-dialog [visible]="true" appendTo="body" [modal]="true" [resizable]="false" [dismissableMask]="true" [closable]="false" position="center"
          [style]="{maxWidth: '95%', maxHeight: '95%'}"
          [contentStyle]="{width: '100%', height: '100%'}"
>
    <p-header class="rsd-header">
        {{ title | translate | titlecase }}
    </p-header>

    <ohh-image-cropper
            [currentImageUri]="currentImageUri"
            [initImage]="initImage"
            [imgRatioNumerator]="imgRatioNumerator"
            [imgRatioDenominator]="imgRatioDenominator"
            (save)="onSave($event)"
            (cancel)="onCancel()"
    ></ohh-image-cropper>

    <ohh-waiting-indicator *ngIf="showSpinner"></ohh-waiting-indicator>
</p-dialog>
