<div id="ol-component-container">
	<div id="ol-rpe-title">
		Email Sent
	</div>
	<div>
		<fa-icon icon="circle-check"></fa-icon>
	</div>
	<div id="ol-rpe-body">
		We just sent an email with instructions for resetting your account password. If you don’t see the email, make sure the email address is correct and check your spam folder.
	</div>
</div>
