import { Component } from '@angular/core';

@Component({
    selector: 'ohh-reset-password-email-sent',
    templateUrl: './reset-password-email-sent.component.html',
    styleUrl: './reset-password-email-sent.component.less'
})
export class ResetPasswordEmailSentComponent {

}
