import { Observable, of, tap } from 'rxjs';
import { CachedCollection } from '../_common/cached-collection';
import { Injectable } from '@angular/core';
import { OhhServiceCategory } from './models/ohh-service-category.model';
import { ServiceCategoryApiService } from './service-category-api.service';

@Injectable({
    providedIn: 'root'
})
export class ServiceCategoryManager {

    private serviceCategoriesForHomePageCache: OhhServiceCategory[] = [];
    private _categories: CachedCollection<OhhServiceCategory>;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private serviceCategoryApiService: ServiceCategoryApiService) { }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    getCategoriesForHomePage(bypassCache = false): Observable<OhhServiceCategory[]> {
        if (!bypassCache && this.serviceCategoriesForHomePageCache != null && this.serviceCategoriesForHomePageCache.length > 0) {
            return of(this.serviceCategoriesForHomePageCache);
        }

        return this.serviceCategoryApiService.getCategoriesForHomePage(bypassCache)
            .pipe(tap((categories: OhhServiceCategory[]) => this.serviceCategoriesForHomePageCache = categories));
    }

    getCategories(bypassCache = false): Observable<OhhServiceCategory[]> {
        if (!bypassCache && this._categories && !this._categories.isExpired) {
            return of(this._categories.collection);
        }

        // If the cache is expired, update the cache.
        const obs = this.serviceCategoryApiService.getCategories()
            .pipe(tap(categories => this._categories = new CachedCollection(categories, 30)));

        // Unless we want to explicitly bypass cache, always return it, even if it is expired.
        // The next call will return updated data. This way we never have to wait for data.
        return (!bypassCache && this._categories) ? of(this._categories.collection) : obs;
    }
}
