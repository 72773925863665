<!-- src/app/image-cropper/image-cropper.component.html -->
<div id="ol-image-cropper-container">

    <div id="ol-ic-orig-img-container" class="ol-flex-col-center">
        <div class="ol-flex-col-center">
            <div class="ic-img-header prevent-select">{{'CASE_SENSITIVE.ORIGINAL_IMAGE' | translate}}</div>
            <div id="ol-ic-wrapper">
                <div *ngIf="selectedFile == null && initImage == null" class="ic-ph"></div>
                <image-cropper
                        *ngIf="selectedFile != null  || initImage != null"
                        [imageChangedEvent]="imageChangedEvent"
                        [imageFile]="initImage"
                        (imageCropped)="imageCropped($event)"
                        [maintainAspectRatio]="true"
                        [aspectRatio]="imgRatioNumerator / imgRatioDenominator"
                        format="png"
                ></image-cropper>
            </div>
            </div>
        <div class="file-upload ol-green-button ol-ic-buttons">
            <input type="file" id="fileInput" accept=".jpg, .jpeg, .png, .gif" (change)="fileChangeEvent($event)" />
            <label for="fileInput" class="custom-file-upload">
                Choose File
            </label>
        </div>
    </div>

    <div id="ol-image-preview">
        <div id="ol-image-preview-wrapper">
            <div class="ic-img-header">{{'CASE_SENSITIVE.IMAGE_PREVIEW' | translate}}</div>
            <div *ngIf="selectedFile == null && initImage == null" class="img-prev-ph preview"></div>
            <img *ngIf="croppedImage" [src]="croppedImage" />
        </div>
        <div id="preview-buttons" class="ol-ic-buttons">
            <button class="ol-green-button inverted" (click)="onCancel()">{{ 'STRINGS.CANCEL' | translate }}</button>
            <button class="ol-green-button" [disabled]="selectedFile == null && initImage == null" (click)="saveImage()">{{'CASE_SENSITIVE.SAVE_IMAGE' | translate}}</button>
        </div>
    </div>
</div>
