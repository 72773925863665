<div class="ol-cp-home-container">
	<p-accordion [activeIndex]="0">
		<p-accordionTab [header]="'Business Profile' | translate">

			<div class="ol-profile-section-container">
				<div class="olps-header prevent-select">{{ 'CASE_SENSITIVE.SERVICE_CAT_N_SPECIALTIES' | translate }}</div>

				<form [formGroup]="formGroup">
					<div class="olps-content-container">
						<div class="olps-intro" [innerHTML]="('PHRASE.BIZ_CAT_INTRO' | translate) + '.'"></div>

						<div class="ol-cp-user-info padded column">

							<div class="ol-labeled-input-text">
								<label for="insured" class="prevent-select sub-section-header">{{ 'CASE_SENSITIVE.MAIN_CATEGORY' | translate }}*</label>
								<div id="category" class="ol-field-checkbox row">
									<p-radioButton
											*ngFor="let cat of serviceCategories"
											class="service-category"
											formControlName="category"
											[value]="cat.id"
											[label]="cat.name"
									></p-radioButton>
								</div>
							</div>

							<div class="ol-labeled-input-text">
								<label for="insured" class="prevent-select sub-section-header">{{ 'CASE_SENSITIVE.SUB_CATEGORIES' | translate }}*</label>
								<div id="sub-category" class="ol-field-checkbox row">
									<p-checkbox
											*ngFor="let cat of selectedCategory?.subCategories"
											class="service-category"
											formControlName="subCategories"
											[value]="cat.id"
											[label]="cat.name"
									></p-checkbox>
								</div>
							</div>

							<div class="ol-labeled-input-text">
								<div class="prevent-select ol-label-n-instructions">
									<label for="specialties" class="sub-section-header">{{ 'STRINGS.SPECIALTIES' | translate }}</label>
									<span>({{ 'PHRASE.SPECIALITY_ENTRY_INSTRUCTIONS' | translate }})</span>
								</div>
								<p-chips
										id="specialties"
										formControlName="specialties"
										styleClass="ol-bp-specialties"
										[maxLength]="100"
										[allowDuplicate]="false"
										[addOnBlur]="true"
								></p-chips>
							</div>
						</div>

						<div class="ol-btn-container">
							<button class="ol-green-button inverted" (click)="onCancel()">Cancel</button>
							<button class="ol-green-button" (click)="onSubmit()" [disabled]="!isFormDirtyAndValid()">Save</button>
						</div>

					</div>
				</form>
			</div>

			<div class="ol-profile-section-container">
				<div class="olps-header prevent-select">{{ 'CASE_SENSITIVE.BUSINESS_INFORMATION' | translate }}</div>

				<form [formGroup]="formGroup">

					<div class="olps-content-container">

						<div class="ol-cp-user-info">

							<div class="ol-info-col left">
								<div class="ol-labeled-input-text">
									<label for="businessName" class="prevent-select">{{ 'CASE_SENSITIVE.BUSINESS_NAME' | translate }}</label>
									<input pInputText id="businessName" formControlName="businessName">
								</div>
								<div class="ol-labeled-input-text">
									<label for="primaryService" class="prevent-select">{{ 'CASE_SENSITIVE.PRIMARY_SERVICE' | translate }}</label>
									<input pInputText id="primaryService" formControlName="primaryService">
								</div>
								<div class="ol-labeled-input-text">
									<label for="businessPhone" class="prevent-select">{{ 'CASE_SENSITIVE.BUSINESS_PHONE' | translate }}</label>
									<input pInputText id="businessPhone" formControlName="businessPhone">
								</div>
								<div class="ol-labeled-input-text">
									<label for="businessEmail" class="prevent-select">{{ 'CASE_SENSITIVE.BUSINESS_EMAIL' | translate }}</label>
									<input pInputText id="businessEmail" formControlName="businessEmail">
								</div>
								<div class="ol-labeled-input-text">
									<label for="website" class="prevent-select">{{ 'STRINGS.WEBSITE' | translate }}</label>
									<input pInputText id="website" formControlName="website">
								</div>
							</div>

							<div class="ol-info-col right">

								<div class="ol-labeled-input-text">
									<label for="contact-methods" class="prevent-select">{{ 'PHRASE.HOW_CUSTOMERS_CONTACT' | translate }}</label>
									<div id="contact-methods" class="ol-field-checkbox row">
										<p-checkbox
												*ngFor="let method of contactMethods"
												formControlName="contactMethods"
												[label]="method.name"
												[value]="method.id"
										></p-checkbox>
									</div>
								</div>

								<div class="ol-labeled-input-text">
									<label for="contactForRate" class="prevent-select">{{ 'CASE_SENSITIVE.RATE_INFORMATION' | translate }}</label>
									<div id="contactForRate" class="ol-field-checkbox col">
										<p-radioButton
												[inputId]="'isTrue'"
												formControlName="contactForRate"
												[value]="true"
												[label]="'STRINGS.CONTACT_FOR_RATE' | translate"
										></p-radioButton>
										<div style="display: flex; ">
											<p-radioButton
													[inputId]="'isFalse'"
													formControlName="contactForRate"
													[value]="false"
													[label]="('CASE_SENSITIVE.HOURLY_RATE' | translate) + ':'"
											></p-radioButton>
											<p-inputNumber
													formControlName="rate"
													mode="currency"
													currency="USD"
													locale="en-US"
													size="3"
													[min]="0"
											></p-inputNumber>
										</div>
									</div>
								</div>

								<div class="ol-labeled-input-text">
									<label for="payment-methods" class="prevent-select">{{ 'PHRASE.WHAT_PAYMENT_METHODS_ACCEPTED' | translate }}</label>
									<div id="payment-methods" class="ol-field-checkbox row">
										<p-checkbox
												*ngFor="let method of paymentMethods"
												formControlName="paymentMethods"
												[label]="method.name"
												[value]="method.id"
										></p-checkbox>
									</div>
								</div>

								<div class="ol-labeled-input-text">
									<label for="insured" class="prevent-select">{{ 'PHRASE.IS_BUSINESS_INSURED' | translate }}</label>
									<div id="insured" class="ol-field-checkbox row">
										<p-radioButton
												[inputId]="'isTrue'"
												formControlName="insured"
												[value]="true"
												[label]="'STRINGS.YES' | translate"
										></p-radioButton>
										<p-radioButton
												[inputId]="'isFalse'"
												formControlName="insured"
												[value]="false"
												[label]="'STRINGS.NO' | translate"
										></p-radioButton>
									</div>
								</div>

							</div>

						</div>

						<div class="ol-btn-container">
							<button class="ol-green-button inverted" (click)="onCancel()">Cancel</button>
							<button class="ol-green-button" (click)="onSubmit()" [disabled]="!isFormDirtyAndValid()">Save</button>
						</div>

					</div>

				</form>

			</div>

			<div class="ol-profile-section-container">
				<div class="olps-header prevent-select">{{ 'CASE_SENSITIVE.UPLOAD_BIZ_PROFILE_PICTURE' | translate }}</div>

				<div class="ol-cp-drop-wrapper">
					<div class="olps-img-container biz-img"
						 [ngClass]="{'placeholder': bizProfileImageUri == null}"
						 [ngStyle]="{'background-image': 'url(' + (bizProfileImageUri == null ? placeholderImageUrl : '') + ')'}"
					>
						<img *ngIf="bizProfileImageUri != null" [src]="bizProfileImageUri" alt="Profile Picture">
					</div>
					<div class="ol-cp-drop-container file-drop-zone"
						 (dragover)="onDragOver($event)"
						 (dragleave)="onDragLeave($event)"
						 (drop)="onDrop($event, ImageType.BusinessProfile)"
					>
						<input type="file" #bizFileInput (change)="onBizFileSelected($event)" accept=".jpg, .jpeg, .png" style="display: none;"/>
						<button class="ol-green-button inverted" (click)="onUploadBizFile()">{{ 'CASE_SENSITIVE.UPLOAD_A_FILE' | translate }}</button>
						<span class="ol-cp-drop-text">...{{ 'STRINGS.OR_DRAG_DROP_FILE' | translate }}</span>
					</div>
				</div>

			</div>

			<div class="ol-profile-section-container">
				<div class="olps-header prevent-select">{{ 'CASE_SENSITIVE.YOUR_BIO' | translate }}</div>

				<form [formGroup]="formGroup">
					<div class="olps-content-container">
						<div class="olps-intro">
							{{ 'PHRASE.BIZ_BIO_INTRO' | translate }}
						</div>

						<div class="ol-cp-user-info">
							<div class="ol-info-col left">
								<div class="ol-labeled-input-text">
									<label for="introduction" class="prevent-select">{{ 'STRINGS.INTRODUCTION' | translate }}:</label>
									<textarea
											id="introduction"
											pInputTextarea
											cols="30"
											rows="7"
											autoResize="true"
											formControlName="introduction"
									></textarea>
								</div>
								<div class="ol-labeled-input-text">
									<label for="services" class="prevent-select">{{ 'PHRASE.WHAT_I_DO' | translate }}:</label>
									<textarea
											id="services"
											pInputTextarea
											cols="30"
											rows="7"
											autoResize="true"
											formControlName="services"
									></textarea>
								</div>
							</div>

							<div class="ol-info-col right">
								<div class="ol-labeled-input-text">
									<label for="experience" class="prevent-select">{{ 'STRINGS.EXPERIENCE' | translate }}:</label>
									<textarea
											id="experience"
											class="ol-input-ta"
											pInputTextarea
											cols="30"
											rows="7"
											autoResize="true"
											formControlName="experience"
									></textarea>
								</div>
								<div class="ol-labeled-input-text">
									<label for="why-hire" class="prevent-select">{{ 'PHRASE.WHY_HIRE_ME' | translate }}?</label>
									<textarea
											id="why-hire"
											class="ol-input-ta"
											pInputTextarea
											cols="30"
											rows="7"
											autoResize="true"
											formControlName="whyHire"
									></textarea>
								</div>
							</div>
						</div>

						<div class="ol-btn-container">
							<button class="ol-green-button inverted" (click)="onCancel()">Cancel</button>
							<button class="ol-green-button" (click)="onSubmit()" [disabled]="!isFormDirtyAndValid()">Save</button>
						</div>

					</div>
				</form>
			</div>

			<div class="ol-profile-section-container">
				<div class="olps-header prevent-select">{{ 'CASE_SENSITIVE.GALLERY_PHOTOS' | translate }}</div>

				<div class="olps-content-container">
					<div id="olpd-gallery-container">

						<p-carousel
								[value]="galleryImages"
								[numVisible]="3"
								[numScroll]="3"
								[responsiveOptions]="responsiveOptions"
								[showIndicators]="false"
								styleClass="ol-provider-carousel account"
						>
							<ng-template let-image pTemplate="item">
								<div id="olpd-car-img-container" style="position: relative;">
									<p-image
											*ngIf="image?.blobName != null"
											[src]="ohhCdnUrl + '/' + image.blobName"
											[preview]="true"
											[loading]="'eager'"
											[style]="{'width': '100%', 'height': '100%', 'object-fit': 'cover'}"
											[imageStyle]="{'width': '100%', 'height': '100%', 'object-fit': 'cover'}"
											appendTo="body"
									></p-image>
									<div *ngIf="image == null" class="ol-cp-drop-wrapper">
										<div class="ol-cp-drop-container file-drop-zone"
											 (dragover)="onDragOver($event)"
											 (dragleave)="onDragLeave($event)"
											 (drop)="onDrop($event, ImageType.BusinessGallery)"
										>
											<input type="file" #galleryFileInput (change)="onGalleryFileSelected($event)" accept=".jpg, .jpeg, .png" style="display: none;"/>
											<button class="ol-green-button inverted" (click)="onUploadGalleryFile()">{{ 'CASE_SENSITIVE.UPLOAD_A_FILE' | translate }}</button>
											<span class="ol-cp-drop-text">...{{ 'STRINGS.OR_DRAG_DROP_FILE' | translate }}</span>
										</div>
									</div>
									<div *ngIf="image != null" id="ol-gal-img-del" (click)="confirmImageDeletion($event, image)">
										<fa-icon [icon]="['far', 'rectangle-xmark']"></fa-icon>
									</div>
								</div>
							</ng-template>
						</p-carousel>

						<p-confirmDialog styleClass="ol-confirm-dlg" [appendTo]="'body'" />
					</div>
				</div>
			</div>

			<div class="ol-profile-section-container">
				<div class="olps-header prevent-select">{{ 'CASE_SENSITIVE.SOCIAL_MEDIA_LINKS' | translate }}</div>

				<form [formGroup]="formGroup">

					<div class="olps-content-container">

						<div class="ol-cp-user-info">

							<div class="ol-info-col left">
								<div class="ol-labeled-input-text">
									<div class="ol-icon-label prevent-select">
										<fa-icon [icon]="['fab', 'instagram']"></fa-icon>
										<label for="smInstagram">{{ 'CASE_SENSITIVE.SOCIAL_URL_INSTAGRAM' | translate }}</label>
									</div>
									<input pInputText id="smInstagram" formControlName="instaUrl">
								</div>
								<div class="ol-labeled-input-text">
									<div class="ol-icon-label prevent-select">
										<fa-icon id="fb" [icon]="['fab', 'facebook-f']"></fa-icon>
										<label for="smFacebook">{{ 'CASE_SENSITIVE.SOCIAL_URL_FACEBOOK' | translate }}</label>
									</div>
									<input pInputText id="smFacebook" formControlName="facebookUrl">
								</div>
								<div class="ol-labeled-input-text">
									<div class="ol-icon-label prevent-select">
										<fa-icon [icon]="['fab', 'linkedin-in']"></fa-icon>
										<label for="smLinkedIn">{{ 'CASE_SENSITIVE.SOCIAL_URL_LINKED_IN' | translate }}</label>
									</div>
									<input pInputText id="smLinkedIn" formControlName="linkedInUrl">
								</div>
							</div>

							<div class="ol-info-col right">

								<div class="ol-labeled-input-text">
									<div class="ol-icon-label prevent-select">
										<fa-icon [icon]="['fab', 'youtube']"></fa-icon>
										<label for="smYouTube">{{ 'CASE_SENSITIVE.SOCIAL_URL_YOU_TUBE' | translate }}</label>
									</div>
									<input pInputText id="smYouTube" formControlName="youTubeUrl">
								</div>
								<div class="ol-labeled-input-text">
									<div class="ol-icon-label prevent-select">
										<fa-icon [icon]="['fab', 'tiktok']"></fa-icon>
										<label for="smTikTok">{{ 'CASE_SENSITIVE.SOCIAL_URL_TIK_TOK' | translate }}</label>
									</div>
									<input pInputText id="smTikTok" formControlName="tikTokUrl">
								</div>

							</div>

						</div>

						<div class="ol-btn-container">
							<button class="ol-green-button inverted" (click)="onCancel()">Cancel</button>
							<button class="ol-green-button" (click)="onSubmit()" [disabled]="!isFormDirtyAndValid()">Save</button>
						</div>

					</div>

				</form>

			</div>


			<div class="ol-profile-section-container">
				<div class="olps-header locations">
					<span class="prevent-select">{{ 'CASE_SENSITIVE.WORKING_LOCATIONS' | translate }}</span>
					<p-triStateCheckbox id="allLocations" [(ngModel)]="areAllLocationsSelected" (click)="onAllLocationsClick($event)" (onChange)="onAllLocationsChange($event)"></p-triStateCheckbox>
					<label for="allLocations" class="prevent-select">All Locations</label>
				</div>

				<form [formGroup]="formGroup">
					<div class="olps-content-container">

						<div class="ol-cp-user-info padded">
							<div id="locations" class="ol-field-checkbox row locations">
								<p-checkbox
										*ngFor="let loc of locations"
										formControlName="locations"
										[value]="loc.id"
										[label]="loc.name"
								></p-checkbox>
							</div>
						</div>

						<div class="ol-btn-container">
							<button class="ol-green-button inverted" (click)="onCancel()">Cancel</button>
							<button class="ol-green-button" (click)="onSubmit()" [disabled]="!isFormDirtyAndValid()">Save</button>
						</div>

					</div>
				</form>
			</div>

			<!--			</div>-->

		</p-accordionTab>
	</p-accordion>

</div>

<ohh-image-cropper-dlg
		*ngIf="showImageSelectionDlg"
		[title]="(profileImageType != ImageType.BusinessGallery ? 'PHRASE.SELECT_PROFILE_IMAGE' : 'PHRASE.SELECT_GALLERY_IMAGE') | translate"
		[initImage]="initFile"
		[imgRatioNumerator]="1.52"
		(save)="profileImageType == ImageType.BusinessProfile ? onSaveProfileImage($event) : onSaveGalleryImage($event)"
		(cancel)="showImageSelectionDlg = false;"
></ohh-image-cropper-dlg>
