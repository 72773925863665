import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { filter, forkJoin, Observable, take } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BroadcastService } from '../../common/services/broadcast.service';
import { ComponentBase } from '../../shared/misc/component-base';
import { DeviceManagerService } from '../../common/services/device-manager.service';
import { ErrorLoggingService } from '../../common/services/error-logging.service';
import { OhhProviderCardInfo } from '../../api/provider/models/ohh-provider-card-info.model';
import { OhhServiceCategory } from '../../api/service-category/models/ohh-service-category.model';
import { ProviderManagerService } from '../../api/provider/provider-manager.service';
import { ServiceCategoryManager } from '../../api/service-category/service-category-manager.service';
import { Util } from '../../common/Utils/util';

@UntilDestroy()
@Component({
    selector: 'ohh-services',
    templateUrl: './services.component.html',
    styleUrl: './services.component.less'
})
export class ServicesComponent extends ComponentBase implements OnInit {

    private serviceCategories: OhhServiceCategory[] = [];
    private categoryId: string;
    private subCategoryId: string;
    protected category: OhhServiceCategory;
    protected subCategory: OhhServiceCategory;
    protected isDemo = false;
    protected providers: OhhProviderCardInfo[];

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(protected override broadcastService: BroadcastService,
                protected deviceManager: DeviceManagerService,
                private logger: ErrorLoggingService,
                private providerManager: ProviderManagerService,
                private route: ActivatedRoute,
                private router: Router,
                private serviceCategoryManager: ServiceCategoryManager
    ) {
        super(broadcastService);
    }

    override ngOnInit() {
        this.router.events
            .pipe(
                untilDestroyed(this),
                filter(event => event instanceof NavigationEnd)
            )
            .subscribe((event: any) => {
                this.getComponentData();
                setTimeout(() => this.broadcastService.scrollToTop.next(null))
            });

        // this.broadcastService.showMegaMenu
        //     .pipe(untilDestroyed(this))
        //     .subscribe((show: boolean) => {
        //         this.showSearchTab = true;
        //     });

        this.getComponentData();
        setTimeout(() => this.broadcastService.scrollToTop.next(null))
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    // protected onShowSearchTab() {
    //     this.showSearchTab = !this.showSearchTab;
    // }
    //
    // protected onCategorySelected() {
    //     this.showSearchTab = !this.showSearchTab;
    // }

    protected onProviderCardClicked(providerId: string) {
        if (Util.isGuid(providerId)) {
            this.router.navigate(['/providers', providerId, 'detail']);
        }
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private getComponentData() {
        this.categoryId = this.route.snapshot.queryParams['category'];
        this.subCategoryId = this.route.snapshot.queryParams['subCategory'];

        const sources: Observable<any>[] = [
            this.serviceCategoryManager.getCategories().pipe(take(1)),
            this.providerManager.getProvidersForSubCategory(this.subCategoryId),
            this.providerManager.getMockProviders(Math.floor(Math.random() * 5) + 3)
        ]

        forkJoin(sources)
            .subscribe({
                next: ([categories, providers, mockProviders]) => {
                    this.serviceCategories = categories;

                    this.isDemo = providers?.length < 1;
                    this.providers = !this.isDemo ? providers :  mockProviders;

                    this.updateCategoryInformation();
                },
                error: err => {
                    this.logger.handleError(new Error('Unable to retrieve component data.', { cause: err }));
                }
            });

    }

    private updateCategoryInformation() {
        if (this.serviceCategories?.length > 0) {
            this.category = this.serviceCategories.find(c => c.id == this.categoryId)
            if (this.category == null) return;

            this.subCategory = this.category.subCategories?.find(c => c.id == this.subCategoryId);
        }
    }

}
