<div id="ol-page-footer-container">
    <!--    <div class="ol-footer-row">-->
    <!--        <img src="../../../../assets/images/ohhlook-logo.png" alt="">-->
    <!--    </div>-->

    <div *ngIf="!deviceManager.isTablet && !deviceManager.isMobile">
        <div class="ol-footer-row company">
            <div class="ol-footer-cat-container">
                <span class="ol-cat ol-category prevent-select">Company</span>
                <ng-container [ngTemplateOutlet]="companyLinks"></ng-container>
            </div>

            <div class="ol-footer-cat-container">
                <span class="ol-cat ol-category prevent-select">Log In</span>
                <ng-container [ngTemplateOutlet]="joinUsLinks"></ng-container>
            </div>

            <div class="ol-footer-cat-container">
                <span class="ol-cat ol-category prevent-select">Help Center</span>
                <ng-container [ngTemplateOutlet]="helpCenterLinks"></ng-container>
            </div>

            <ng-container [ngTemplateOutlet]="newsletter"></ng-container>
        </div>

        <ng-container *ngIf="!deviceManager.isTablet && !deviceManager.isMobile" [ngTemplateOutlet]="socialLinks"></ng-container>

        <div class="ol-footer-row prevent-select" style="border-bottom: 3px solid #8dc73f; margin: 50px 0 10px 0; display: flex; justify-content: center;">
            <span id="ol-neighborhood-hdr">Neighborhoods We Serve</span>
        </div>

        <div class="ol-footer-row">
            <div class="ol-footer-cat-container hoods" *ngFor="let group of cities | slice:0:(Math.ceil(cities.length / 6)); let groupIndex = index">
                <ng-container *ngFor="let city of cities.slice(groupIndex * 6, (groupIndex + 1) * 6)">
                      <span class="ol-cat ol-sub-category" (click)="onCityClick(city)">
                        <span class="prevent-select">{{ city?.name }}</span>
                      </span>
                </ng-container>
            </div>
        </div>
    </div>

    <div *ngIf="deviceManager.isTablet || deviceManager.isMobile" style="margin: 30px 0 20px 0;">
        <p-accordion *ngIf="deviceManager.isTablet || deviceManager.isMobile" styleClass="ol-mobile-accordion">
            <p-accordionTab header="Company">
                <div class="ol-accordion-links">
                    <ng-container [ngTemplateOutlet]="companyLinks"></ng-container>
                </div>
            </p-accordionTab>
            <p-accordionTab header="Log In">
                <div class="ol-accordion-links">
                    <ng-container [ngTemplateOutlet]="joinUsLinks"></ng-container>
                </div>
            </p-accordionTab>
            <p-accordionTab header="Help Center">
                <div class="ol-accordion-links">
                    <ng-container [ngTemplateOutlet]="helpCenterLinks"></ng-container>
                </div>
            </p-accordionTab>
            <p-accordionTab header="Neighborhoods We Serve">
                <div id="ol-mobile-city-container">
                    <div class="ol-mc-col">
                        <ng-container *ngFor="let city of cities; let i = index">
                            <div class="ol-mc-link pre" *ngIf="i % 2 === 0" (click)="onCityClick(city)">
                                <span class="prevent-select">{{ city?.name }}</span>
                            </div>
                        </ng-container>
                    </div>
                    <div class="ol-mc-col">
                        <ng-container *ngFor="let city of cities; let i = index">
                            <div class="ol-mc-link" *ngIf="i % 2 !== 0" (click)="onCityClick(city)">
                                <span class="prevent-select">{{ city?.name }}</span>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </p-accordionTab>
        </p-accordion>

        <ng-container *ngIf="deviceManager.isTablet || deviceManager.isMobile" [ngTemplateOutlet]="socialLinks" style="margin-top: 20px;"></ng-container>
    </div>

    <div class="ol-footer-row copyright prevent-select" [ngClass]="{'left-justify': !deviceManager.isLessThanMedium}">
        <span>© 2024 Ohh Look, LLC. All Rights Reserved.</span>
    </div>

</div>

<ng-template #socialLinks>
    <div class="ol-footer-row" [ngClass]="{'left-justify': !deviceManager.isLessThanMedium}">
        <div id="ol-social-links">
            <a href="https://www.facebook.com/ohhlookofficial1/" target="_blank">
                <fa-icon [icon]="['fab', 'facebook-f']"></fa-icon>
            </a>
            <a href="https://www.instagram.com/ohhlook_official/" target="_blank">
                <fa-icon [icon]="['fab', 'instagram']"></fa-icon>
            </a>
            <a href="https://www.tiktok.com/@ohhlookofficial?lang=en" target="_blank">
                <fa-icon [icon]="['fab', 'tiktok']"></fa-icon>
            </a>
            <a href="https://www.youtube.com/channel/UCBCUkaCzHGYrOmHPPRjdyAA" target="_blank">
                <fa-icon [icon]="['fab', 'youtube']"></fa-icon>
            </a>
            <a href="https://www.linkedin.com/company/ohh-look/" target="_blank">
                <fa-icon [icon]="['fab', 'linkedin-in']"></fa-icon>
            </a>
        </div>
    </div>
</ng-template>

<ng-template #newsletter>
    <div class="ol-footer-cat-container newsletter">
        <span class="ol-cat ol-category prevent-select">Newsletter</span>
        <div style="margin: 0 5px;">
            <div id="ol-email-signup" class="prevent-select">
                Sign up to receive our newsletter and keep up with everything new at <i>{{ 'OHH.APP_NAME' | translate }}</i>.
            </div>

            <ohh-email-sign-up></ohh-email-sign-up>
        </div>
    </div>
</ng-template>

<ng-template #companyLinks>
    <span class="ol-cat ol-sub-category" (click)="onNavigate('/company/our-story')"><span class="prevent-select">Our Story</span></span>
    <span class="ol-cat ol-sub-category" (click)="onNavigate('/company/blog')"><span class="prevent-select">Blog</span></span>
    <span class="ol-cat ol-sub-category" (click)="onNavigate('/company/careers')"><span class="prevent-select">Careers</span></span>
    <span class="ol-cat ol-sub-category" (click)="onNavigate('/company/contact-us')"><span class="prevent-select">Contact Us</span></span>
</ng-template>

<ng-template #joinUsLinks>
    <span class="ol-cat ol-sub-category" (click)="onNavigate('/register')"><span class="prevent-select">Create an Account</span></span>
    <span class="ol-cat ol-sub-category" (click)="onNavigate('/pro')"><span class="prevent-select">Become a Service Provider</span></span>
</ng-template>

<ng-template #helpCenterLinks>
    <span class="ol-cat ol-sub-category" (click)="onNavigate('/help-center/customer-faq')"><span class="prevent-select">Customer FAQs</span></span>
    <span class="ol-cat ol-sub-category" (click)="onNavigate('/help-center/provider-faq')"><span class="prevent-select">Provider FAQs</span></span>
    <span class="ol-cat ol-sub-category" (click)="onNavigate('/help-center/terms')"><span class="prevent-select">Terms of Use</span></span>
    <span class="ol-cat ol-sub-category" (click)="onNavigate('/help-center/privacy-policy')"><span class="prevent-select">Privacy Policy</span></span>
    <span class="ol-cat ol-sub-category" (click)="onNavigate('/help-center/safety')"><span class="prevent-select">Safety</span></span>
</ng-template>
